import React from 'react'
import { useState } from 'react'

import { useForm } from 'react-hook-form'
import type { Page, Website } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { Button } from '@/components/ui/Button'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/Card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/Form'
import { Input } from '@/components/ui/Input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/Select'
import { Textarea } from '@/components/ui/Textarea'

const SAVE_WEBSITE = gql`
  mutation SaveWebsiteGeneralSettingsMutation(
    $id: String!
    $input: UpdateWebsiteInput!
  ) {
    updateWebsite(id: $id, input: $input) {
      __typename
      id
      googleAnalyticsId
      contentInHead
      contactFormEmail
      agencyShortCode
      lastUpdated
    }
  }
`

const SAVE_PAGE = gql`
  mutation SavePageSettingsPanelMutation(
    $id: String!
    $input: UpdatePageInput!
  ) {
    updatePage(id: $id, input: $input) {
      __typename
      id
      seoTitle
      seoDescription
      seoMetaTags
      name
      path
      lastUpdated
    }
  }
`

type GeneralSettingsProps = {
  website: Website
}

const GeneralSettings = ({ website }: GeneralSettingsProps) => {
  const form = useForm({
    defaultValues: {
      agencyShortCode: website?.agencyShortCode || '',
      googleAnalyticsId: website?.googleAnalyticsId || '',
      contentInHead: website?.contentInHead || '',
      contactFormEmail: website?.contactFormEmail || '',
      pageName: '',
      pageUrl: '',
      seoTitle: '',
      seoDescription: '',
      seoMetaTags: '',
    },
  })

  const [currentPage, setCurrentPage] = useState(
    website?.Pages.find((page) => page.isHomepage)
  )
  const [googleAnalyticsId, setGoogleAnalyticsId] = useState(
    website?.googleAnalyticsId || ''
  )
  const [contactFormEmail, setContactFormEmail] = useState(
    website?.contactFormEmail || ''
  )
  const [contentInHead, setContentInHead] = useState(
    website?.contentInHead || ''
  )
  const [agencyShortCode, setAgencyShortCode] = useState(
    website?.agencyShortCode || ''
  )
  const [seoTitle, setSeoTitle] = useState(currentPage?.seoTitle || '')
  const [seoDescription, setSeoDescription] = useState(
    currentPage?.seoDescription || ''
  )
  const [seoMetaTags, setSeoMetaTags] = useState(currentPage?.seoMetaTags || '')
  const [currentPageName, setCurrentPageName] = useState(
    currentPage?.name || ''
  )
  const [currentPageUrl, setCurrentPageUrl] = useState(
    currentPage?.path === 'home' ? '/' : `/${currentPage?.path || ''}`
  )
  const [isSaving, setIsSaving] = useState(false)
  const [pagePathErrorString, setPagePathErrorString] = useState<string | null>(
    null
  )
  const [pageNameErrorString, setPageNameErrorString] = useState<string | null>(
    null
  )

  const [saveWebsite] = useMutation(SAVE_WEBSITE)
  const [savePage] = useMutation(SAVE_PAGE)

  const onSettingsFormSubmit = async (formData) => {
    if (
      seoTitle !== currentPage.seoTitle ||
      seoDescription !== currentPage.seoDescription ||
      seoMetaTags !== currentPage.seoMetaTags ||
      currentPageName !== currentPage.name ||
      currentPageUrl !==
        (currentPage.path === 'home' ? '/' : `/${currentPage.path}`)
    ) {
      let newPath = currentPageUrl.replace(/^\/+/, '')
      if (newPath === '') {
        newPath = 'home'
      }

      let newName = currentPageName

      if (
        website.Pages.some(
          (page) => page.path === newPath && page.id !== currentPage.id
        )
      ) {
        setPagePathErrorString('Another page already has this URL path.')
        return
      }

      if (
        website.Pages.some(
          (page) => page.name === newName && page.id !== currentPage.id
        )
      ) {
        setPageNameErrorString('Another page already has this name.')
        return
      }

      setPagePathErrorString(null)
      setPageNameErrorString(null)

      if (currentPage.isHomepage) {
        newPath = 'home'
        newName = 'Home'
      }

      setCurrentPage((prevPage) => ({
        ...prevPage,
        seoTitle,
        seoDescription,
        seoMetaTags,
        name: newName,
        path: newPath,
      }))

      setIsSaving(true)
      await savePage({
        variables: {
          id: currentPage.id,
          input: {
            seoTitle,
            seoDescription,
            seoMetaTags,
            path: newPath,
            name: newName,
          },
        },
      })
      setIsSaving(false)
    }

    if (
      website.googleAnalyticsId !== googleAnalyticsId ||
      website.contentInHead !== contentInHead ||
      website.contactFormEmail !== contactFormEmail ||
      website.agencyShortCode !== agencyShortCode
    ) {
      setIsSaving(true)
      await saveWebsite({
        variables: {
          id: website.id,
          input: {
            googleAnalyticsId,
            agencyShortCode,
            contactFormEmail,
            contentInHead,
          },
        },
      })
      setIsSaving(false)
    }
  }

  const changeCurrentPage = (page: Page) => {
    setCurrentPage(page)
    setSeoTitle(page.seoTitle || '')
    setSeoDescription(page.seoDescription || '')
    setSeoMetaTags(page.seoMetaTags || '')
    setCurrentPageName(page.name || '')
    setCurrentPageUrl(page.path === 'home' ? '/' : `/${page.path}`)
  }

  return (
    <div className="container max-w-3xl space-y-8 py-8">
      <Form {...form}>
        <Card>
          <CardHeader>
            <CardTitle>Website Settings</CardTitle>
            <CardDescription>
              These settings apply to your entire website
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            {website?.user?.agencyOwnerName && (
              <FormField
                name="agencyShortCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Agency Website Subdomain</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="<subdomain>.youragency.com"
                        value={agencyShortCode}
                        onChange={(e) =>
                          setAgencyShortCode(e.target.value.trim())
                        }
                        className="max-w-[450px]"
                      />
                    </FormControl>
                    {agencyShortCode && (
                      <p className="text-sm text-muted-foreground">
                        <span className="mr-1">Access this website at</span>
                        <a
                          href={`https://${agencyShortCode}.${website.user.agencyOwnerName}`}
                          className="text-primary hover:underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {agencyShortCode}.{website.user.agencyOwnerName}
                        </a>
                      </p>
                    )}
                  </FormItem>
                )}
              />
            )}

            <FormField
              name="googleAnalyticsId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Google Analytics ID</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="GA-XXXXXXXXXX"
                      value={googleAnalyticsId}
                      onChange={(e) =>
                        setGoogleAnalyticsId(e.target.value.trim())
                      }
                      className="max-w-[200px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              name="contentInHead"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Content in &lt;head&gt;</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Add your custom head content here..."
                      value={contentInHead}
                      onChange={(e) => setContentInHead(e.target.value)}
                      rows={4}
                    />
                  </FormControl>
                  <p className="text-sm text-muted-foreground">
                    For script tags, embeds, fonts, favicons, or other content
                    you want placed in the &lt;head&gt; of your website.
                  </p>
                </FormItem>
              )}
            />

            <FormField
              name="contactFormEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Contact Form Email Override</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="someone@email.com"
                      value={contactFormEmail}
                      onChange={(e) =>
                        setContactFormEmail(e.target.value.trim())
                      }
                      className="max-w-[400px]"
                    />
                  </FormControl>
                  <p className="text-sm text-muted-foreground">
                    Leave this blank and we'll send any contact form submissions
                    to the email on your user account.
                  </p>
                </FormItem>
              )}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Page Settings</CardTitle>
            <CardDescription>
              These settings apply to individual pages
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <Select
              value={currentPage?.id}
              onValueChange={(value) => {
                const page = website?.Pages.find((p) => p.id === value)
                if (page) changeCurrentPage(page)
              }}
            >
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Select a page" />
              </SelectTrigger>
              <SelectContent>
                {website?.Pages.map((page) => (
                  <SelectItem key={page.id} value={page.id}>
                    <span>{page.name}</span>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {currentPage && (
              <div className="space-y-6">
                <FormField
                  name="pageName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Page Name</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={currentPageName}
                          onChange={(e) => setCurrentPageName(e.target.value)}
                          disabled={currentPage?.isHomepage}
                        />
                      </FormControl>
                      {currentPage?.isHomepage && (
                        <p className="text-sm text-muted-foreground">
                          You cannot change the name of the homepage.
                        </p>
                      )}
                      {pageNameErrorString && (
                        <FormMessage>{pageNameErrorString}</FormMessage>
                      )}
                    </FormItem>
                  )}
                />

                <FormField
                  name="pageUrl"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Page URL</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={currentPageUrl}
                          onChange={(e) => setCurrentPageUrl(e.target.value)}
                          disabled={currentPage?.isHomepage}
                        />
                      </FormControl>
                      {currentPage?.isHomepage && (
                        <p className="text-sm text-muted-foreground">
                          You cannot change the url of the homepage.
                        </p>
                      )}
                      {pagePathErrorString && (
                        <FormMessage>{pagePathErrorString}</FormMessage>
                      )}
                    </FormItem>
                  )}
                />

                <FormField
                  name="seoTitle"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>SEO Title</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={seoTitle}
                          onChange={(e) => setSeoTitle(e.target.value)}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  name="seoDescription"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>SEO Description</FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          value={seoDescription}
                          onChange={(e) => setSeoDescription(e.target.value)}
                          rows={3}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  name="seoMetaTags"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Meta Tags</FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          value={seoMetaTags}
                          onChange={(e) => setSeoMetaTags(e.target.value)}
                          rows={8}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            )}
          </CardContent>
        </Card>

        <div className="flex items-center gap-4 pt-6">
          <Button
            type="submit"
            onClick={onSettingsFormSubmit}
            disabled={isSaving}
            size="lg"
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
          <p className="text-sm text-muted-foreground">
            You must publish your website for these changes to be deployed to
            your website
          </p>
        </div>
      </Form>
    </div>
  )
}

export default GeneralSettings
