import { useEffect, useRef, useState } from 'react'

import { Prisma } from '@prisma/client'
import { Font } from '@samuelmeuli/font-manager'
import * as cheerio from 'cheerio'
import clsx from 'clsx'
import { usePostHog } from 'posthog-js/react'
import type {
  FindWebsitePanelQuery,
  FindWebsitePanelQueryVariables,
  UpdateWebsiteInput,
  Website,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'
import { type TypedDocumentNode, useQuery, useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ChatPanel, { ChatPanelHandler } from 'src/components/Chat/ChatPanel'
import FavoriteImagePickerPanel, {
  FavoriteImagePickerPanelHandler,
} from 'src/components/Chat/FavoriteImagePickerPanel'
import GalleryPanel from 'src/components/Chat/GalleryPanel'
import Navbar from 'src/components/Chat/Navbar'
import PageToolbar from 'src/components/Chat/PageToolbar'
import SettingsPanel, {
  SettingsPanelHandler,
} from 'src/components/Chat/SettingsPanel'
import TemplatePickerPanel from 'src/components/Chat/TemplatePickerPanel'
import WebsiteLoadingPanel from 'src/components/Chat/WebsiteLoadingPanel'
import WebsiteLoadingProgressBar from 'src/components/Chat/WebsiteLoadingProgressBar'
import WebsitePanel, {
  WebsitePanelHandler,
} from 'src/components/Chat/WebsitePanel'
import {
  CodeSection,
  FrameElementEvent,
  PageWithCodeSections,
} from 'src/components/PageFrame/PageFrame'
import { buildFontUrl, defaultFonts } from 'src/lib/fonts'
import {
  Media,
  replaceClickedElementWithMedia,
  getUpdatedSeoImageTags,
  updateElementImage,
} from 'src/lib/media'
import { useBreakpoint } from 'src/lib/useTailwindBreakpoint'

type WebsiteWidth = 'mobile' | 'tablet' | 'laptop' | 'desktop'

const FIND_WEBSITE: TypedDocumentNode<
  FindWebsitePanelQuery,
  FindWebsitePanelQueryVariables
> = gql`
  query FindWebsitePanelQuery($id: String!) {
    website: website(id: $id) {
      id
      name
      url
      businessName
      businessAbout
      createdAt
      startedGeneratingAt
      finishedGeneratingAt
      proPublishedAt
      onboardingQuestions
      onboardingAnswers
      hasRunOnboardingFunctions
      additionalInformationFields
      notifyWhenFinishedEmail
      nextStepStateIndex
      hasCompletedOnboarding
      hasCompletedBlogOnboarding
      hasAddedBlogLinkToHomepage
      uniqueUserToken
      headerCodeSection
      footerCodeSection
      colorPalette
      googleAnalyticsId
      contactFormEmail
      agencyShortCode
      scriptsInHtml
      contentInHead
      fonts
      customDomain
      verifiedDomain
      stripePlan
      stripeIsAnnualPlan
      cloudflareZoneId
      cloudflareZoneActivatedOn
      cloudflareOriginalDNSHost
      cloudflareOriginalNameservers
      cloudflareOriginalRegistrar
      cloudflareNameservers
      nameservers
      waitingForSSLCertificate
      analyticsId
      lastUpdated
      user {
        id
        agencyOwnerName
      }
      Pages {
        id
        name
        path
        isHomepage
        html
        codeSections
        imagesKeyword
        primaryColor
        startedGeneratingAt
        finishedGeneratingAt
        hasAppliedPrimaryColorOnFirstLoad
        seoDescription
        seoTitle
        seoMetaTags
        lastUpdated
      }
      Domain {
        id
        nameserverStatus
        verificationStatus
      }
    }
  }
`

const SAVE_WEBSITE = gql`
  mutation SaveChatWebsiteMutation($id: String!, $input: UpdateWebsiteInput!) {
    updateWebsite(id: $id, input: $input) {
      __typename
      id
      googleAnalyticsId
      headerCodeSection
      footerCodeSection
      fonts
    }
  }
`

const SAVE_ACTIVE_PAGE = gql`
  mutation SaveActivePageMutation($id: String!, $input: UpdatePageInput!) {
    updatePage(id: $id, input: $input) {
      __typename
      id
      codeSections
      imagesKeyword
      primaryColor
      hasAppliedPrimaryColorOnFirstLoad
    }
  }
`

const START_BUILDING_WEBSITE = gql`
  mutation StartBuildingWebsiteMutation($id: String!, $templateId: String!) {
    startBuildingWebsite(id: $id, templateId: $templateId) {
      __typename
      id
      startedGeneratingAt
    }
  }
`

const START_BUILDING_PAGE = gql`
  mutation StartBuildingPageMutation($id: String!) {
    startBuildingPage(id: $id) {
      __typename
      id
      startedGeneratingAt
      finishedGeneratingAt
    }
  }
`

const PUBLISH_IMAGE_AND_GET_URL = gql`
  mutation PublishImageAndGetUrlMutation(
    $id: String!
    $input: PublishImageAndGetUrlInput!
  ) {
    publishImageAndGetUrl(id: $id, input: $input)
  }
`

const PUBLISH_WEBSITE = gql`
  mutation PublishWebsiteMutation(
    $id: String!
    $publishToFreeDomain: Boolean!
    $publishToProDomain: Boolean!
  ) {
    publishWebsite(
      id: $id
      publishToFreeDomain: $publishToFreeDomain
      publishToProDomain: $publishToProDomain
    ) {
      __typename
      id
      freePublishedAt
      proPublishedAt
    }
  }
`

const CLAIM_WEBSITE = gql`
  mutation ClaimWebsiteMutation($id: String!) {
    claimWebsite(id: $id) {
      __typename
      id
      user {
        id
        email
      }
    }
  }
`

const DELETE_PAGE = gql`
  mutation DeletePageMutation($id: String!) {
    deletePage(id: $id) {
      __typename
      id
    }
  }
`

const UNPUBLISH_WEBSITE = gql`
  mutation UnpublishWebsiteMutation(
    $id: String!
    $unpublishFreeDomain: Boolean
    $unpublishProDomain: Boolean
  ) {
    unpublishWebsite(
      id: $id
      unpublishFreeDomain: $unpublishFreeDomain
      unpublishProDomain: $unpublishProDomain
    ) {
      __typename
      id
      freePublishedAt
      proPublishedAt
    }
  }
`

const CREATE_CHAT_PAGE = gql`
  mutation CreateChatPageMutation($input: CreatePageInput!) {
    createChatPage(input: $input) {
      __typename
      id
      name
      path
      isHomepage
      html
      codeSections
      imagesKeyword
      primaryColor
      startedGeneratingAt
      finishedGeneratingAt
      hasAppliedPrimaryColorOnFirstLoad
      seoDescription
      seoTitle
      seoMetaTags
    }
  }
`

const cleanupCodeSections = (codeSections: CodeSection[]): CodeSection[] => {
  return codeSections.map((section) => {
    if (!section.html) {
      return section
    }

    const $ = cheerio.load(section.html)

    // Remove classes that shouldn't be persisted
    $('.clicked-element').removeClass('clicked-element')
    $('.hovered-element').removeClass('hovered-element')
    $('.clicked-code-section').removeClass('clicked-code-section')
    $('[contenteditable]').removeAttr('contenteditable')

    // Ensure the main container has the correct class and ID
    const mainContainer = $('body').children().first()
    mainContainer.addClass('code-section')
    if (!mainContainer.attr('id')) {
      mainContainer.attr('id', section.id)
    }

    return {
      ...section,
      html: $('body').html(),
    }
  })
}

const WebsiteChatPage = ({ id, panel }: { id: string; panel: string }) => {
  const posthog = usePostHog()
  const websitePanelRef = useRef<WebsitePanelHandler>(null)
  const chatPanelRef = useRef<ChatPanelHandler>(null)
  const skipHistoryUpdateRef = useRef<boolean>(false)
  const isGeneratingWebsiteRef = useRef<boolean>(false)
  const favoriteImagePickerPanelRef =
    useRef<FavoriteImagePickerPanelHandler>(null)
  const isPickingFavoriteImagesRef = useRef<boolean>(false)
  const showedLoginComponentMessageRef = useRef<boolean>(false)
  const prevFinishedGeneratingAt = useRef<string>()
  const settingsPanelRef = useRef<SettingsPanelHandler>(null)
  const hasColorPaletteChangedRef = useRef<boolean>(false)

  const [clickedElement, setClickedElement] = useState<FrameElementEvent>(null)
  const [panelName, setPanelName] = useState(panel || 'website')
  const [settingsPanelInitialTab, setSettingsPanelInitialTab] = useState('')
  const [clickedElementEvent, setClickedElementEvent] =
    useState<FrameElementEvent | null>(null)
  const [website, setWebsite] = useState<
    Website & {
      headerCodeSection: CodeSection
      footerCodeSection: CodeSection
    }
  >(null)
  const [activePage, setActivePage] = useState<PageWithCodeSections>(null)
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [didSaveError, setDidSaveError] = useState(false)
  const [websiteWidth, setWebsiteWidth] = useState<WebsiteWidth>('desktop')
  const [history, setHistory] = useState([])
  const [historyIndex, setHistoryIndex] = useState(0)
  const [shouldDisableChatSubmit] = useState(false)
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [galleryType, setGalleryType] = useState<
    'images' | 'videos' | 'illustrations'
  >('images')
  const [fonts, setFonts] = useState<string[]>(null)
  const [isOnboarding, setIsOnboarding] = useState<boolean>(null)
  const [gettingStartedState, setGettingStartedState] = useState<
    | 'edit'
    | 'setupDomain'
    | 'upgrade'
    | 'publish'
    | 'publishing'
    | 'published'
    | 'done'
  >('done')
  const [isDeletingPage, setIsDeletingPage] = useState(false)
  const [isChatPanelExpanded, setIsChatPanelExpanded] = useState(true)
  const [colorPalette, setColorPalette] = useState<Prisma.JsonValue>(
    website?.colorPalette || {}
  )
  const [chatWaitingText, setChatWaitingText] = useState<string>('')

  const swappingImageRef = useRef<FrameElementEvent>(null)
  const isDesktop = useBreakpoint('lg')

  const { loading, error, refetch } = useQuery<FindWebsitePanelQuery>(
    FIND_WEBSITE,
    {
      variables: { id },
      onCompleted(data) {
        setWebsite(data.website as any)
        if (activePage) {
          setActivePage(
            (data.website.Pages as PageWithCodeSections[]).find(
              (v) => v.id === activePage.id
            )
          )
        }
      },
    }
  )
  const [saveWebsite] = useMutation(SAVE_WEBSITE)
  const [saveActivePage] = useMutation(SAVE_ACTIVE_PAGE)
  const [startBuildingWebsite] = useMutation(START_BUILDING_WEBSITE)
  const [startBuildingPage] = useMutation(START_BUILDING_PAGE)
  const [publishImageAndGetUrl] = useMutation(PUBLISH_IMAGE_AND_GET_URL)
  const [publishWebsite] = useMutation(PUBLISH_WEBSITE)
  const [unpublishWebsite] = useMutation(UNPUBLISH_WEBSITE)
  const [claimWebsite] = useMutation(CLAIM_WEBSITE)
  const [deletePageMutation] = useMutation(DELETE_PAGE)
  const [createChatPage] = useMutation(CREATE_CHAT_PAGE)

  const { isAuthenticated, loading: authLoading, logIn, hasRole } = useAuth()

  useEffect(() => {
    if (posthog && website?.analyticsId) {
      posthog.identify(website.analyticsId, {
        websiteId: website?.id,
      })
    }
  }, [website, posthog])

  // only on load, if mobile, set website width to mobile
  useEffect(() => {
    if (!isDesktop) {
      setWebsiteWidth('mobile')
    } else {
      setWebsiteWidth('desktop')
    }
  }, [isDesktop])

  useEffect(() => {
    if (isDesktop) {
      setIsChatPanelExpanded(true)
    } else {
      setIsChatPanelExpanded(false)
    }
  }, [isDesktop])

  useEffect(() => {
    if (
      history.length === 0 &&
      website &&
      website.finishedGeneratingAt &&
      activePage &&
      activePage.codeSections
    ) {
      setHistory([
        {
          activePage,
          headerCodeSection: website.headerCodeSection,
          footerCodeSection: website.footerCodeSection,
        },
      ])
    }
  }, [activePage, website])

  useEffect(() => {
    let intervalId
    if (
      activePage &&
      activePage.startedGeneratingAt &&
      !activePage.finishedGeneratingAt
    ) {
      // if the page is still generating, keep refreshing
      refetch()
      intervalId = setInterval(() => {
        console.log('refetching active page...')
        if (activePage.finishedGeneratingAt) {
          clearInterval(intervalId)
        }
        refetch()
      }, 5000) // 5 second interval

      // Cleanup function that clears the interval when the component is unmounted or finishedGeneratingAt is not null
      return () => {
        clearInterval(intervalId)
        if (activePage.finishedGeneratingAt) {
          refetch()
        }
      }
    }

    if (activePage && activePage.finishedGeneratingAt && intervalId) {
      clearInterval(intervalId)
    }
  }, [activePage])

  useEffect(() => {
    if (authLoading) {
      return
    }

    if (!website) {
      return
    }

    if (website.finishedGeneratingAt && isAuthenticated) {
      posthog?.capture('User Logged In')
      setIsOnboarding(false)
    } else {
      setIsOnboarding(true)
    }
  }, [website, isAuthenticated, authLoading])

  useEffect(() => {
    if (!website) {
      return
    }

    //isOnboarding being undefined means we're still loading
    if (!isOnboarding && isOnboarding !== false) {
      //we're still loading
      return
    }

    if (website && !activePage) {
      setActivePage(
        (website.Pages as PageWithCodeSections[]).find((v) => v.isHomepage)
      )
      setFonts(website.fonts || defaultFonts)
    }

    if (website && !website.startedGeneratingAt) {
      if (isGeneratingWebsiteRef.current === true) {
        return // already generating
      }

      console.log('generating new website...')

      isGeneratingWebsiteRef.current = true

      setPanelName('templatePicker')
    }

    if (
      website &&
      website.startedGeneratingAt &&
      !website.finishedGeneratingAt &&
      isPickingFavoriteImagesRef.current === false
    ) {
      setPanelName('websiteLoading')
    }
  }, [website, isOnboarding])

  useEffect(() => {
    if (authLoading) {
      return
    }

    if (!website) {
      return
    }

    //if user is not logged in
    if (
      website &&
      website.finishedGeneratingAt &&
      !isAuthenticated &&
      !website.user
    ) {
      if (showedLoginComponentMessageRef.current === true) {
        return
      }

      showedLoginComponentMessageRef.current = true

      setTimeout(() => {
        if (chatPanelRef.current) {
          chatPanelRef.current.sendAssistantMessage(
            `Website generated! You can change text, images, colors, add new sections, add new pages, and more... but first, you'll need to log in.`
          )
          chatPanelRef.current.showComponent('loginComponent')
        }
      }, 1000)
    }

    if (prevFinishedGeneratingAt.current === undefined) {
      prevFinishedGeneratingAt.current = website.finishedGeneratingAt
      return
    }

    //in the flow of making the website
    if (
      website &&
      website.startedGeneratingAt &&
      prevFinishedGeneratingAt.current !== website.finishedGeneratingAt &&
      website.finishedGeneratingAt &&
      isAuthenticated
    ) {
      prevFinishedGeneratingAt.current = website.finishedGeneratingAt

      if (showedLoginComponentMessageRef.current === true) {
        return
      }

      showedLoginComponentMessageRef.current = true

      setTimeout(() => {
        if (chatPanelRef.current) {
          chatPanelRef.current.sendAssistantMessage(
            `Website generated! Click on anything on your website to change it.`
          )
        }
      }, 1000)
    }
  }, [authLoading, isAuthenticated, website, chatPanelRef.current])

  useEffect(() => {
    const asyncClaimWebsite = async () => {
      await claimWebsite({
        variables: {
          id: website.id,
        },
      })

      await refetch()
    }

    if (
      isAuthenticated &&
      website &&
      !website.user &&
      !loading &&
      !hasRole('admin')
    ) {
      asyncClaimWebsite()
    }
  }, [website, isAuthenticated, loading])

  useEffect(() => {
    console.log('in useEffect for website', website)
    if (!website) {
      return
    }

    if (
      panelName !== 'website' &&
      website.finishedGeneratingAt &&
      !website.hasCompletedOnboarding
    ) {
      onWebsiteFinishedGenerating()
    }

    // Check if finishedGeneratingAt is null, which means we need to keep refreshing
    if (
      website &&
      website.startedGeneratingAt &&
      !website.finishedGeneratingAt
    ) {
      console.log('refetching website...')
      const intervalId = setInterval(() => {
        if (website.finishedGeneratingAt) {
          clearInterval(intervalId)
          isGeneratingWebsiteRef.current = false
          onWebsiteFinishedGenerating()
        }

        refetch()
      }, 5000) // 5 second interval

      // Cleanup function that clears the interval when the component is unmounted or finishedGeneratingAt is not null
      return () => {
        clearInterval(intervalId)
        if (website.finishedGeneratingAt) {
          isGeneratingWebsiteRef.current = false
          onWebsiteFinishedGenerating()
        }
      }
    }
  }, [website?.startedGeneratingAt, website?.finishedGeneratingAt, refetch])

  useEffect(() => {
    if (website && website.colorPalette) {
      setColorPalette(website.colorPalette)
    }
  }, [website])

  useEffect(() => {
    // save page and website
    if (!isDataChanged) {
      return
    }
    setIsDataChanged(false)
    saveThisPage()
  }, [isDataChanged])

  useEffect(() => {
    if (!website) {
      return
    }

    if (
      !isOnboarding &&
      website.finishedGeneratingAt &&
      !website.customDomain &&
      !website.verifiedDomain &&
      !website.proPublishedAt
    ) {
      setGettingStartedState('edit')
    } else if (
      !isOnboarding &&
      website.finishedGeneratingAt &&
      website.customDomain &&
      !website.verifiedDomain &&
      !website.proPublishedAt
    ) {
      setGettingStartedState('setupDomain')
    } else if (
      !isOnboarding &&
      website.finishedGeneratingAt &&
      website.customDomain &&
      website.verifiedDomain &&
      website.stripePlan === 'Free'
    ) {
      setGettingStartedState('upgrade')
    } else if (
      !isOnboarding &&
      website.finishedGeneratingAt &&
      website.customDomain &&
      website.verifiedDomain &&
      !website.proPublishedAt &&
      website.stripePlan !== 'Free'
    ) {
      setGettingStartedState('publish')
    } else if (website.proPublishedAt) {
      setGettingStartedState('done')
    }
  }, [
    isOnboarding,
    website,
    website?.finishedGeneratingAt,
    website?.customDomain,
    website?.verifiedDomain,
    website?.proPublishedAt,
  ])

  const onFrameElementClick = (event: FrameElementEvent) => {
    console.log('onFrameElementClick', isDesktop, isChatPanelExpanded)

    // if swapping images, handle that
    if (
      swappingImageRef.current &&
      swappingImageRef.current.element !== event.element
    ) {
      swapImages(swappingImageRef.current, event)
      return
    }

    // when on mobile, and chat panel is expanded, collapse the chat history and don't highlight the element until they click again
    if (!isDesktop && isChatPanelExpanded) {
      setIsChatPanelExpanded(false)
      clearClickedElement()
      return
    }

    setClickedElement(event)
    setClickedElementEvent(event)

    posthog?.capture('Element Clicked')
  }

  const clearClickedElement = () => {
    console.log('clearing clicked element in website chat page')
    setClickedElement(null)
    setClickedElementEvent(null)
    if (websitePanelRef.current) {
      websitePanelRef.current.clearClickedElement()
    }
  }

  const saveThisPage = async () => {
    if (isSaving) {
      return
    }
    setIsSaving(true)

    try {
      const { updatedPage, headerCodeSection, footerCodeSection } =
        websitePanelRef.current.getUpdatedPage()

      // Clean up code sections
      const cleanedCodeSections = cleanupCodeSections(updatedPage.codeSections)

      await saveActivePage({
        variables: {
          id: updatedPage.id,
          input: {
            codeSections: cleanedCodeSections,
            seoMetaTags: updatedPage.seoMetaTags,
          },
        },
      })

      setWebsite((prevWebsite) => ({
        ...prevWebsite,
        lastUpdated: new Date().toISOString(),
      }))

      const input: UpdateWebsiteInput = {}
      if (headerCodeSection) {
        input.headerCodeSection = cleanupCodeSections([headerCodeSection])[0]
      }
      if (footerCodeSection) {
        input.footerCodeSection = cleanupCodeSections([footerCodeSection])[0]
      }
      if (JSON.stringify(fonts) !== JSON.stringify(website.fonts)) {
        input.fonts = fonts
      }

      if (hasColorPaletteChangedRef.current) {
        input.colorPalette = colorPalette
      }

      // only save if there is a change
      if (Object.keys(input).length > 0) {
        await saveWebsite({
          variables: {
            id: website.id,
            input: input,
          },
        })
      }

      if (skipHistoryUpdateRef.current === false) {
        setHistory((prevHistory) => {
          const newHistory = prevHistory.slice(0, historyIndex + 1)
          newHistory.push({
            activePage,
            headerCodeSection: website.headerCodeSection,
            footerCodeSection: website.footerCodeSection,
          })

          setHistoryIndex(newHistory.length - 1)
          return newHistory
        })
      }

      skipHistoryUpdateRef.current = false

      hasColorPaletteChangedRef.current = false
      setDidSaveError(false)
      setIsSaving(false)
    } catch (e) {
      console.log('Error getting updated page', e)
      setIsSaving(false)
      setDidSaveError(true)
      return
    }
  }

  const updateHtmlFromUserPrompt = async (
    sectionToEditId: string,
    prompt: string
  ): Promise<{ message: string; ok: boolean }> => {
    const success = () => ({
      message: 'Website updated successfully',
      ok: true,
    })
    const failure = (error: string) => ({
      message: `Unable to update website: ${error}`,
      ok: false,
    })

    if (!website.finishedGeneratingAt) {
      return failure(
        "Your website is still generating. Please try again once it's done"
      )
    }
    //send a request to getHtmlFromPrompt with prompt, and codeSection where clickedElementContent is the html of the element that was clicked

    console.log('sectionId provided by AI:', sectionToEditId)
    console.log(
      'sectionId from clicked element:',
      clickedElementEvent?.codeSectionElement?.id
    )

    const clickedElementSectionHtml =
      clickedElementEvent?.codeSectionElement?.outerHTML

    const clickedElementHtml = clickedElementEvent?.element?.outerHTML

    let sectionToEditHtml

    console.log('has clickedElementSectionHtml?', !!clickedElementSectionHtml)
    console.log('sectionId:', sectionToEditId)
    if (sectionToEditId) {
      let codeSection

      console.log(
        'website header code section id:',
        website.headerCodeSection?.id
      )
      console.log(website.headerCodeSection)
      if (website.headerCodeSection?.id === sectionToEditId) {
        codeSection = website.headerCodeSection
      }

      console.log('codeSection:', codeSection)

      const codeSectionWithId = activePage.codeSections.find(
        (section) => section.id === sectionToEditId
      ) as CodeSection

      console.log('codeSectionWithId?:', codeSectionWithId)

      if (codeSectionWithId) {
        codeSection = codeSectionWithId
      }
      console.log('codeSection:', codeSection)

      if (website.footerCodeSection?.id === sectionToEditId) {
        codeSection = website.footerCodeSection
      }
      console.log('codeSection:', codeSection)

      sectionToEditHtml = codeSection?.html
    }

    let apiResponse: Response

    console.log('sectionToEditHtml:', sectionToEditHtml)
    if (!sectionToEditHtml) {
      return failure('No code section found with that id')
    }

    console.log('calling getHtmlFromChatUserPrompt')
    try {
      apiResponse = await fetch(
        process.env.LAMBDA_GET_HTML_FROM_CHAT_USER_PROMPT ||
          `${process.env.API_BASE_URL}/getHtmlFromChatUserPrompt`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: website.id,
            prompt,
            sectionToEditHtml: sectionToEditHtml,
            clickedElementSectionHtml: clickedElementSectionHtml,
            clickedElementHtml: clickedElementHtml,
          }),
        }
      )
    } catch (error) {
      console.log(error)
      return failure(error)
    }

    console.log('got api response:', apiResponse)

    if (!apiResponse.ok) {
      console.log('error:', apiResponse)
      return failure(await apiResponse.text())
    }

    const res = await apiResponse.json()

    const updatedCodeSections = cleanupCodeSections([
      {
        id: sectionToEditId,
        html: res.data,
      },
    ])

    const updatedHtml = updatedCodeSections[0].html

    if (
      website.headerCodeSection &&
      website.headerCodeSection.id == sectionToEditId
    ) {
      setWebsite({
        ...website,
        headerCodeSection: {
          ...(website.headerCodeSection as CodeSection),
          html: updatedHtml,
        },
      })
    }

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: prevPage.codeSections.map((section) => {
        if (section.id === sectionToEditId) {
          return {
            ...section,
            html: updatedHtml,
          }
        } else {
          return section
        }
      }),
    }))

    if (
      website.footerCodeSection &&
      website.footerCodeSection.id == sectionToEditId
    ) {
      setWebsite({
        ...website,
        footerCodeSection: {
          ...(website.footerCodeSection as CodeSection),
          html: updatedHtml,
        },
      })
    }

    setIsDataChanged(true)
    clearClickedElement()
    return success()
  }

  const functions = {
    editHtmlOfSection: async (toolResponse: string) => {
      setChatWaitingText('Editing section...')
      let res = { sectionToEditId: '', userRequest: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      console.log('editHtmlOfSection:', res.sectionToEditId, res.userRequest)
      return await updateHtmlFromUserPrompt(
        res.sectionToEditId,
        res.userRequest
      )
    },
    navigateToPanel: (toolResponse: string) => {
      setChatWaitingText('Navigating to panel...')
      let res = { panelName: 'website' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      navigateToPanel(res.panelName)

      return {
        message: 'Navigated to settings panel',
        ok: true,
      }
    },
    addNewSectionAboveSelectedSection: async (toolResponse: string) => {
      setChatWaitingText('Adding new section...')
      let res = { sectionUserRequest: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      const aiResponse = await createNewCodeSectionAbove(res.sectionUserRequest)

      return aiResponse
    },
    addNewSectionBelowSelectedSection: async (toolResponse: string) => {
      setChatWaitingText('Adding new section...')
      let res = { sectionUserRequest: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      const aiResponse = await createNewCodeSectionBelow(res.sectionUserRequest)

      return aiResponse
    },
    openImageGallery: (galleryType) => {
      setChatWaitingText('Opening image gallery...')
      setGalleryType(galleryType)
      setIsGalleryOpen(true)
    },
    publishWebsite: async () => {
      console.log('in chat publish website')
      setChatWaitingText('Publishing website...')
      return await chatPublishWebsite()
    },
    unpublishWebsite: async () => {
      setChatWaitingText('Unpublishing website...')
      return await chatUnpublishWebsite()
    },
    showChatComponent(toolResponse: string) {
      let res = { componentName: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      if (chatPanelRef.current) {
        setChatWaitingText('Showing component: ' + res.componentName)
        chatPanelRef.current.showComponent(res.componentName)
        return {
          message: `Sucessfully showed component`,
          ok: true,
        }
      }

      return {
        message: `Error showing component. Chat panel not open`,
        ok: false,
      }
    },
    addNewPage: async (toolResponse) => {
      setChatWaitingText('Creating new page...')
      let res = { pageUserRequest: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      if (website.stripePlan === 'Free') {
        return {
          message:
            'You need to upgrade your plan in the settings to add more pages',
          ok: false,
        }
      }

      const aiResponse = await createNewPage(res.pageUserRequest)

      return aiResponse
    },
    getHtmlOfEverySectionOnThisPage: async () => {
      setChatWaitingText('Browsing website HTML...')
      const aiResponse = getHtmlSectionsForAi()
      return aiResponse
    },
  }

  const getHtmlSectionsForAi = () => {
    if (!activePage) {
      return {
        message: 'No active page',
        ok: false,
      }
    }

    const sections = []

    if (website.headerCodeSection) {
      sections.push({
        id: website.headerCodeSection.id,
        html: website.headerCodeSection.html,
      })
    }

    activePage.codeSections.forEach((section) => {
      sections.push({
        id: section.id,
        html: section.html,
      })
    })

    if (website.footerCodeSection) {
      sections.push({
        id: website.footerCodeSection.id,
        html: website.footerCodeSection.html,
      })
    }

    return {
      sections,
    }
  }

  const navigateToPanel = (panelName: string, tabName?: string) => {
    console.log('navigateToPanel', panelName)
    isPickingFavoriteImagesRef.current = false
    if (isDesktop) {
      setIsChatPanelExpanded(true)
    } else {
      setIsChatPanelExpanded(false)
    }
    setPanelName(panelName)
    if (tabName) {
      setSettingsPanelInitialTab(tabName)
    }
    if (panelName === 'website') {
      navigate(routes.websiteChat({ id: website.id }))
    } else if (panelName === 'settings') {
      navigate(routes.websiteChatPanel({ id: website.id, panel: panelName }))
    }
  }

  const navigateToWebsitePage = (pagePath: string) => {
    const page = website.Pages.find((p) => p.path === pagePath)
    if (!page) {
      return
    }

    setActivePage(page as PageWithCodeSections)
  }

  const changeViewportWidth = (
    size: 'mobile' | 'tablet' | 'laptop' | 'desktop'
  ) => {
    setWebsiteWidth(size)
  }

  const onNewPageClicked = () => {
    chatPanelRef.current.sendUserMessage(
      'I want to create a new page for my website'
    )
  }

  const deletePage = async (pageToDelete) => {
    if (isDeletingPage) {
      return
    }

    setIsDeletingPage(true)
    await deletePageMutation({
      variables: {
        id: pageToDelete.id,
      },
    })

    if (activePage.id === pageToDelete.id) {
      const homepage = website.Pages.find((p) => p.isHomepage)
      setActivePage(homepage as PageWithCodeSections)
    }

    await refetch()

    setIsDeletingPage(false)
  }

  const changeActivePage = (page: PageWithCodeSections) => {
    setActivePage(page)
  }

  const onUndoClick = () => {
    console.log('undo')

    setHistoryIndex((prevIndex) => {
      if (prevIndex > 0) {
        const nextIndex = prevIndex - 1
        const historyElement = history[nextIndex]
        setWebsite((websiteVal) => {
          return {
            ...websiteVal,
            headerCodeSection: historyElement.headerCodeSection,
            footerCodeSection: historyElement.footerCodeSection,
          }
        })
        setActivePage(history[nextIndex].activePage)
        skipHistoryUpdateRef.current = true
        setIsDataChanged(true)
        return nextIndex
      }
      return prevIndex
    })
  }

  const onRedoClick = () => {
    console.log('redo')

    setHistoryIndex((prevIndex) => {
      if (prevIndex < history.length - 1) {
        const nextIndex = prevIndex + 1
        const historyElement = history[nextIndex]
        setWebsite((websiteVal) => {
          return {
            ...websiteVal,
            headerCodeSection: historyElement.headerCodeSection,
            footerCodeSection: historyElement.footerCodeSection,
          }
        })
        setActivePage(history[nextIndex].activePage)
        skipHistoryUpdateRef.current = true
        setIsDataChanged(true)
        return nextIndex
      }
      return prevIndex
    })
  }

  const onSaveClick = () => {
    saveThisPage()
  }

  const onColorChangeClick = () => {
    if (!isAuthenticated) {
      if (chatPanelRef.current) {
        chatPanelRef.current.sendAssistantMessage(
          'You need to log in to change the color palette.'
        )
      }
      return
    }

    if (chatPanelRef.current) {
      chatPanelRef.current.showComponent('colorPalettePicker')
    }
  }

  const onSetUpYourDomainButtonClicked = () => {
    //navigateToPanel('settings', 'Custom Domain')
    if (panelName !== 'settings') {
      setPanelName('settings')
      setSettingsPanelInitialTab('Custom Domain')
    } else if (settingsPanelRef.current) {
      settingsPanelRef.current.changeTab('Custom Domain')
    }
  }

  const onAddNewCodeSectionAbove = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    chatPanelRef.current.sendUserMessage(
      "I'd like to add a new section above my selected section."
    )
  }

  const onAddNewCodeSectionBelow = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    chatPanelRef.current.sendUserMessage(
      "I'd like to add a new section below my selected section."
    )
  }

  const onMoveClickedCodeSectionUp = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    if (index === 0) {
      return
    }

    const newCodeSections = [...codeSections]
    const temp = newCodeSections[index - 1]
    newCodeSections[index - 1] = newCodeSections[index]
    newCodeSections[index] = temp

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))

    setIsDataChanged(true)
  }

  const onMoveClickedCodeSectionDown = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    if (index === activePage.codeSections.length - 1) {
      return
    }

    const newCodeSections = [...codeSections]
    const temp = newCodeSections[index + 1]
    newCodeSections[index + 1] = newCodeSections[index]
    newCodeSections[index] = temp

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))

    setIsDataChanged(true)
  }

  const onDeleteClickedCodeSection = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index, 1)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)
  }

  const getNewCodeSection = async (prompt: string) => {
    let response: Response

    try {
      response = await fetch(
        process.env.LAMBDA_GET_NEW_CODE_SECTION_FROM_USER_PROMPT ||
          `${process.env.API_BASE_URL}/getNewCodeSectionFromUserPrompt`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: website.id,
            pageId: activePage.id,
            prompt: prompt,
          }),
        }
      )
    } catch (error) {
      console.log(error)
      return
    }

    if (!response.ok) {
      console.log('error:', response)
      return
    }

    const res = await response.json()

    return res.data
  }

  const createNewCodeSectionAbove = async (sectionUserRequest: string) => {
    if (!activePage || !activePage.codeSections) {
      return {
        message:
          'Unable to add new section above selected section. Try refreshing the page',
        ok: false,
      }
    }

    if (!clickedElementEvent || !clickedElementEvent.codeSectionElement) {
      return {
        message: 'No section selected. Please select a section first',
        ok: false,
      }
    }

    const selectedSectionId = clickedElementEvent.codeSectionElement.id

    console.log('creating new code section above', selectedSectionId)
    console.log('sectionUserRequest:', sectionUserRequest)

    const newCodeSection = await getNewCodeSection(sectionUserRequest)

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === selectedSectionId
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index, 0, newCodeSection)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)

    return {
      message: 'Successfully created the new section',
      ok: true,
    }
  }

  const createNewCodeSectionBelow = async (sectionUserRequest: string) => {
    if (!activePage || !activePage.codeSections) {
      return {
        message:
          'Unable to add new section above selected section. Try refreshing the page',
        ok: false,
      }
    }

    if (!clickedElementEvent || !clickedElementEvent.codeSectionElement) {
      return {
        message: 'No section selected. Please select a section first',
        ok: false,
      }
    }

    const selectedSectionId = clickedElementEvent.codeSectionElement.id

    console.log(
      'clickedElementEvent.codeSectionElement.id:',
      clickedElementEvent.codeSectionElement.id
    )

    console.log('creating new code section below', selectedSectionId)
    console.log('sectionUserRequest:', sectionUserRequest)

    const newCodeSection = await getNewCodeSection(sectionUserRequest)

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === selectedSectionId
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index + 1, 0, newCodeSection)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)

    return {
      message: 'Successfully created the new section',
      ok: true,
    }
  }

  const createNewPage = async (pageUserRequest: string) => {
    try {
      const newPageRes = await createChatPage({
        variables: {
          input: {
            websiteId: website.id,
            description: pageUserRequest,
          },
        },
      })

      setActivePage(newPageRes.data.createChatPage)
    } catch (e) {
      console.error('Error creating new page:', e)
      return {
        message: 'Error creating new page',
        ok: false,
      }
    }

    return {
      message:
        'The page is being generated from the description you provided. It will be ready shortly.',
      ok: true,
    }
  }

  const onPageChange = (params: {
    page: PageWithCodeSections
    headerCodeSection: CodeSection
    footerCodeSection: CodeSection
  }) => {
    setActivePage(params.page)
    setWebsite((prevWebsite) => ({
      ...prevWebsite,
      headerCodeSection: params.headerCodeSection,
      footerCodeSection: params.footerCodeSection,
    }))
    setIsDataChanged(true)
  }

  const onPageFrameLinkClick = (path: string) => {
    const normalizedPath = path.startsWith('/') ? path.slice(1) : path
    const page = (website.Pages as PageWithCodeSections[]).find((page) => {
      return page.path === normalizedPath
    })
    if (page) {
      setActivePage(page)
    }
  }

  const onPageFrameGeneratePageClick = async (pageId: string) => {
    const pageToGenerate = website.Pages.find((page) => page.id === pageId)

    if (!pageToGenerate) {
      return
    }

    try {
      await startBuildingPage({
        variables: {
          id: pageToGenerate.id,
        },
      })

      // Refetch the data to get the updated page information
      await refetch()
    } catch (error) {
      console.error('Error generating page:', error)
    }
  }

  const handleSwapImagesStart = () => {
    console.log('handleSwapImagesStart')
    swappingImageRef.current = clickedElementEvent
    chatPanelRef.current.sendAssistantMessage(
      'Select another image on the page to swap with this image'
    )
    clearClickedElement()
  }

  const swapImages = (first: FrameElementEvent, second: FrameElementEvent) => {
    console.log('swapping images', first, second)
    swappingImageRef.current = null
    if (!first.imageSrc || !second.imageSrc) {
      chatPanelRef.current.sendAssistantMessage(
        "You didn't select a second image to swap with, so I didn't swap anything. You can try again if you like."
      )
      return
    }
    const firstElementClone = first.element.cloneNode(true) as HTMLElement
    const secondElementClone = second.element.cloneNode(true) as HTMLElement

    updateElementImage({
      element: first.element,
      src: second.imageSrc,
      dataMedia: secondElementClone.getAttribute('data-media'),
      backgroundImage:
        firstElementClone.style.backgroundImage && second.imageSrc,
    })

    updateElementImage({
      element: second.element,
      src: first.imageSrc,
      dataMedia: firstElementClone.getAttribute('data-media'),
      backgroundImage:
        secondElementClone.style.backgroundImage && first.imageSrc,
    })

    // save page
    setIsDataChanged(true)

    chatPanelRef.current.sendAssistantMessage(
      "I've swapped the images for you."
    )
  }

  const handleMediaSelected = async (media: Media) => {
    let element = clickedElementEvent?.element

    setIsGalleryOpen(false)
    clearClickedElement()

    if (!element) {
      return
    }

    if (!websitePanelRef.current) {
      return
    }

    // go ahead and replace the element with the media which has a low res url
    element = replaceClickedElementWithMedia({
      element,
      media,
      document: websitePanelRef.current.getDocument(),
    })

    // only upload if it's an image, video upload doesn't work yet
    if (media.type === 'image' || media.type === 'illustration') {
      try {
        const publishResponse = await publishImageAndGetUrl({
          variables: {
            id: website.id,
            input: {
              imageId: media.id,
              imageSource: media.src,
              // imageUrl is only used for upload
              imageUrl: media.src === 'upload' ? media.urls.display : '',
            },
          },
        })
        console.log('media upload response', publishResponse)
        // update the media src with the high res url and replace stuff again
        // unsplash is intentionally a null response, we use their url but still make the api call so that it creates an event for them
        if (publishResponse.data.publishImageAndGetUrl) {
          media.urls.display = publishResponse.data.publishImageAndGetUrl

          replaceClickedElementWithMedia({
            element,
            media,
            document: websitePanelRef.current.getDocument(),
          })
        }
      } catch (error) {
        console.log('Failed to upload media', error, media)
      }
    }

    let newImageUrl: string
    if (media.type === 'image' || media.type === 'illustration') {
      newImageUrl = media.urls.display
    } else {
      newImageUrl = media.urls.small?.image
    }
    if (!newImageUrl) {
      console.error('No new image url', media, website.id)
    }

    const newSeoMetaTags = getUpdatedSeoImageTags({
      element: element as HTMLImageElement,
      activePage,
      newImageUrl,
    })

    try {
      setActivePage((prevPage) => ({
        ...prevPage,
        codeSections:
          websitePanelRef.current.getUpdatedPage().updatedPage.codeSections,
        seoMetaTags: newSeoMetaTags,
      }))
    } catch (e) {
      console.error('Error updating page', e)
    }

    setIsDataChanged(true)
  }

  const onTemplatePicked = async (templateId: string) => {
    startBuildingWebsite({
      variables: {
        id: website.id,
        templateId: templateId,
      },
    })

    setWebsite((prevWebsite) => ({
      ...prevWebsite,
      startedGeneratingAt: new Date().toISOString(),
    }))

    setPanelName('websiteLoading')
  }

  const onPickFavoriteImagesSelected = () => {
    setPanelName('favoriteImagePicker')
    isPickingFavoriteImagesRef.current = true
  }

  const onWebsiteFinishedGenerating = async () => {
    console.log('website finished generating')

    if (isPickingFavoriteImagesRef.current === false) {
      setPanelName('website')
    }

    setWebsite((prevWebsite) => ({
      ...prevWebsite,
      hasCompletedOnboarding: true,
    }))

    await saveWebsite({
      variables: {
        id: website.id,
        input: {
          hasCompletedOnboarding: true,
        },
      },
    })
  }

  const onShowWebsiteClicked = () => {
    const pickedImages = [
      ...favoriteImagePickerPanelRef.current.getSelectedImages(),
    ] as Media[]
    const pickedIllustrations = [
      ...favoriteImagePickerPanelRef.current.getSelectedIllustrations(),
    ] as Media[]

    const newCodeSections = injectPickedMedia(
      activePage,
      pickedImages,
      pickedIllustrations
    )
    if (newCodeSections) {
      setActivePage((prevPage) => ({
        ...prevPage,
        codeSections: newCodeSections,
      }))

      setIsDataChanged(true)
    }

    isPickingFavoriteImagesRef.current = false
    setPanelName('website')
  }

  const injectPickedMedia = (
    activePage: PageWithCodeSections,
    pickedImages: Media[],
    pickedIllustrations: Media[]
  ) => {
    console.log('pickedImages:', pickedImages)
    console.log('pickedIllustrations:', pickedIllustrations)

    if (pickedImages.length === 0 && pickedIllustrations.length === 0) {
      return
    }

    // collect existing media ids
    const usedMediaIds = new Set()
    activePage.codeSections.forEach((codeSection: CodeSection) => {
      const $ = cheerio.load(codeSection.html)

      $('[data-media]').each((_, element) => {
        const $element = $(element)
        const dataMedia = $element.attr('data-media')
        try {
          const mediaData = JSON.parse(dataMedia)
          usedMediaIds.add(mediaData.id)
        } catch (error) {
          console.error('Error parsing data-media attribute:', dataMedia)
        }
      })
    })
    console.log('usedMediaIds:', usedMediaIds)

    // remove pickedMedia that are already used
    pickedImages = pickedImages.filter((image) => !usedMediaIds.has(image.id))
    pickedIllustrations = pickedIllustrations.filter(
      (illustration) => !usedMediaIds.has(illustration.id)
    )
    console.log('pickedImages:', pickedImages)
    console.log('pickedIllustrations:', pickedIllustrations)

    const newCodeSections = activePage.codeSections.map(
      (codeSection: CodeSection) => {
        const $ = cheerio.load(codeSection.html)

        // Replace media that are not in the usedMediaIds set
        $(
          '[data-media]:not([data-dont-replace]):not([data-testimonial-image])'
        ).each((_, element) => {
          const $element = $(element)
          const hasBackgroundImage =
            $element.css('background-image') !== 'none' ||
            $element.attr('class')?.includes('bg-[url(')

          if (hasBackgroundImage || $element.is('img')) {
            let pickedMedia: Media
            const galleryType = $element.attr('data-landingsite-gallery-type')

            if (galleryType === 'image' && pickedImages.length > 0) {
              pickedMedia = pickedImages.shift()
            } else if (
              galleryType === 'illustration' &&
              pickedIllustrations.length > 0
            ) {
              pickedMedia = pickedIllustrations.shift()
            }

            if (pickedMedia) {
              if (hasBackgroundImage) {
                const classList = $element.attr('class') || ''
                const updatedClassList = classList
                  .replace(/bg-\[url\(['"]?([^'"\]]+?)['"]?\)\]/, '')
                  .trim()
                $element.attr('class', updatedClassList)
                $element.addClass(`bg-[url('${pickedMedia.urls.display}')]`)
              } else {
                $element.attr('src', pickedMedia.urls.display)
              }
              $element.attr('data-media', pickedMedia.dataMedia)
            }
          }
        })

        return {
          ...codeSection,
          html: $('body').html(),
        }
      }
    )
    return newCodeSections
  }

  const chatUnpublishWebsite = async (): Promise<{
    message: string
    ok: boolean
  }> => {
    const success = () => ({
      message: 'Website unpublished successfully',
      ok: true,
    })
    const failure = (error: string) => ({
      message: `Unable to publish website: ${error}`,
      ok: false,
    })

    if (!website.proPublishedAt) {
      return success()
    }

    try {
      const res = await unpublishWebsite({
        variables: {
          id: website.id,
          unpublishProDomain: true,
        },
      })
      console.log(res)
    } catch (e) {
      console.log(e)
      return failure(e)
    }

    return success()
  }

  const chatPublishWebsite = async (): Promise<{
    message: string
    ok: boolean
  }> => {
    const success = () => ({
      message: 'Website published successfully',
      ok: true,
    })
    const failure = (error: string) => ({
      message: `Unable to publish website: ${error}`,
      ok: false,
    })

    if (isPublishing) {
      return failure('Website is currently being published')
    }

    if (!isAuthenticated) {
      return failure('You need to log in before publishing')
    }

    if (!website) {
      return failure('Website not found')
    }

    if (!website.customDomain) {
      return failure(
        'You need to link a custom domain to your website first, in the settings'
      )
    }

    if (!website.verifiedDomain) {
      return failure(
        'You need to verify your custom domain first. Follow the instructions in the settings'
      )
    }

    if (website.stripePlan === 'Free') {
      return failure(
        'You need to upgrade to a paid plan before publishing your website'
      )
    }

    setIsPublishing(true)

    setGettingStartedState('publishing')

    try {
      await publishWebsite({
        variables: {
          id: website.id,
          publishToFreeDomain: false,
          publishToProDomain: true,
        },
      })
    } catch (e) {
      console.log(e)
      return failure(e.message)
    }

    setGettingStartedState('published')

    setIsPublishing(false)

    return success()
  }

  const onPublishClicked = async () => {
    if (isPublishing) {
      return
    }

    setIsPublishing(true)

    setGettingStartedState('publishing')

    try {
      await publishWebsite({
        variables: {
          id: website.id,
          publishToFreeDomain: false,
          publishToProDomain: true,
        },
      })
    } catch (e) {
      console.log(e)
    }

    setGettingStartedState('published')

    setIsPublishing(false)
  }

  const onColorPaletteChanged = (palette) => {
    setColorPalette(palette)
  }

  const onColorPaletteKeySaved = async (key: string, value: string) => {
    setWebsite((prevWebsite) => {
      const newColorPalette =
        typeof prevWebsite.colorPalette === 'object' &&
        prevWebsite.colorPalette !== null
          ? { ...prevWebsite.colorPalette }
          : {}

      newColorPalette[key] = value

      return {
        ...prevWebsite,
        colorPalette: newColorPalette,
      }
    })
    hasColorPaletteChangedRef.current = true

    setIsDataChanged(true)
  }

  const onChatUserMessageSubmitted = () => {
    setChatWaitingText('')
  }

  const handleFontChange = (font: Font | null, element: HTMLElement) => {
    // Remove existing font-family classes
    element.classList.remove(
      ...Array.from(element.classList).filter((cls) =>
        cls.startsWith('[font-family')
      )
    )

    if (!font) {
      // If font is null, we've already removed the font class
      return
    }

    // Apply the font class to the element
    const fontFamily = font.family.replace(/\s+/g, '_')
    const fontClass = `[font-family:${fontFamily}]`
    element.classList.add(fontClass)

    const uniqueFonts = new Set([...fonts, font.family])
    const newFonts = Array.from(uniqueFonts)

    // Update the font link in the iframe
    const iframe = websitePanelRef.current.getDocument()
    const fontLink = iframe.head.querySelector(
      '#google-fonts-link'
    ) as HTMLLinkElement

    fontLink.href = buildFontUrl(newFonts)
    setFonts(newFonts)

    // Trigger a save
    setIsDataChanged(true)
  }

  const fontSizeClasses = [
    'text-xs',
    'text-sm',
    'text-base',
    'text-lg',
    'text-xl',
    'text-2xl',
    'text-3xl',
    'text-4xl',
    'text-5xl',
    'text-6xl',
    'text-7xl',
    'text-8xl',
    'text-9xl',
  ]

  const getCurrentBreakpoint = () => {
    switch (websiteWidth) {
      case 'desktop':
        return '2xl'
      case 'laptop':
        return 'xl'
      case 'tablet':
        return 'md'
      case 'mobile':
        return ''
      default:
        return ''
    }
  }

  const handleFontWeightChange = (weight: string, element: HTMLElement) => {
    const breakpoint = getCurrentBreakpoint()

    // Remove existing font-size classes for current breakpoint
    const classesToRemove = fontSizeClasses.map((cls) =>
      breakpoint ? `${breakpoint}:${cls}` : cls
    )
    element.classList.remove(
      ...classesToRemove.filter((cls) => element.classList.contains(cls))
    )

    // Add new font-size class with breakpoint prefix
    const newClass = breakpoint ? `${breakpoint}:${weight}` : weight
    element.classList.add(newClass)

    // Trigger a save
    setIsDataChanged(true)
  }

  const handleFontStyleChange = (
    style: 'bold' | 'italic' | 'underline',
    element: HTMLElement
  ) => {
    switch (style) {
      case 'bold':
        element.classList.toggle('font-bold')
        break
      case 'italic':
        element.classList.toggle('italic')
        break
      case 'underline':
        element.classList.toggle('underline')
        break
    }
    // Trigger a save
    setIsDataChanged(true)
  }

  const handleLinkChange = (href: string | null, element: HTMLElement) => {
    if (!element) return

    if (href) {
      // If href is provided, wrap the element in an <a> tag or update existing link
      let linkElement = element.closest('a')

      if (linkElement) {
        linkElement.href = href
      } else {
        linkElement = document.createElement('a')
        element.parentNode.insertBefore(linkElement, element)
        linkElement.appendChild(element)
      }
    } else {
      // If href is null, remove the link
      const linkElement = element.closest('a')
      try {
        if (linkElement) {
          const parent = linkElement.parentNode
          while (linkElement.firstChild) {
            parent.insertBefore(linkElement.firstChild, linkElement)
          }
          parent.removeChild(linkElement)
        }
      } catch (e) {
        console.error('Error removing link', e)
      }
    }
    // Trigger a save
    setIsDataChanged(true)

    // update the clicked element to be the wrapping anchor tag, or the element itself if no href
    websitePanelRef.current.setClickedElement(element)
  }

  const Loading = ({
    loading,
    website,
  }: {
    loading: boolean
    website?: Website
  }) => {
    return (
      <>
        {loading && !website && (
          <div className="pt-12 text-center">
            <i className="fa-regular fa-spinner-third fa-spin"></i>
          </div>
        )}
      </>
    )
  }

  const Error = ({ website, error }: { website?: Website; error?: Error }) => {
    return (
      <>
        {!website && !error && (
          <div className="pt-12 text-center">
            <div className="mb-4 text-4xl">404 - Website not found</div>
            <div>
              <a href="/" className="text-xl text-blue-500">
                Go back to dashboard
              </a>
            </div>
          </div>
        )}
        {error && error.message === 'You must log in to see this page' && (
          <div className="pt-12 text-center">
            <div className="mb-4 text-3xl">
              You must log in to see this page
            </div>
            <div>
              <button
                className="rounded bg-blue-500 px-4 py-2 text-white"
                onClick={() => {
                  logIn({
                    appState: {
                      targetUrl: `/login-success?redirectTo=${encodeURIComponent(
                        window.location.pathname + window.location.search
                      )}`,
                    },
                  })
                }}
              >
                Log in
              </button>
            </div>
          </div>
        )}
        {error && error.message !== 'You must log in to see this page' && (
          <>
            <div>
              Sorry, there was an error loading your website. Try refreshing the
              page.
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Metadata title="Edit Website" description="Edit Website" />
      <main className="flex h-full w-full flex-col overflow-hidden bg-gray-100">
        <Navbar
          website={website}
          navigateToPanel={navigateToPanel}
          onPublishClicked={onPublishClicked}
          onSetUpDomainClicked={onSetUpYourDomainButtonClicked}
        />

        <div className="relative flex h-full w-full flex-row-reverse overflow-hidden lg:mt-0">
          {/* chat panel */}

          {website &&
            activePage &&
            panelName !== 'templatePicker' &&
            panelName !== 'favoriteImagePicker' &&
            panelName !== 'websiteLoading' && (
              <ChatPanel
                ref={chatPanelRef}
                websiteId={id}
                functions={functions}
                clickedElement={clickedElement}
                handleClearClickedElement={clearClickedElement}
                disableChatSubmit={shouldDisableChatSubmit}
                onMediaUploaded={handleMediaSelected}
                onSwapImages={handleSwapImagesStart}
                isOnboarding={isOnboarding}
                gettingStartedState={gettingStartedState}
                onSetUpYourDomainButtonClicked={onSetUpYourDomainButtonClicked}
                upgradeButtonClicked={() => navigateToPanel('settings', 'plan')}
                expanded={isChatPanelExpanded}
                onToggleChatPanel={(expanded) =>
                  setIsChatPanelExpanded(expanded)
                }
                launchWebsiteButtonClicked={onPublishClicked}
                userDomain={website.verifiedDomain}
                colorPalette={colorPalette}
                onColorPaletteChanged={onColorPaletteChanged}
                onColorPaletteKeySaved={onColorPaletteKeySaved}
                chatWaitingText={chatWaitingText}
                onChatUserMessageSubmitted={onChatUserMessageSubmitted}
              />
            )}

          {/* right panel */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="z-0 grow overflow-hidden pl-0 lg:p-2"
            onClick={() => {
              if (!isDesktop && panelName !== 'website') {
                setIsChatPanelExpanded(false)
              }
            }}
          >
            <div className="relative flex h-full w-full flex-col">
              {loading && <Loading loading={loading} website={website} />}
              {!loading && <Error website={website} error={error} />}

              {/* always render the website so it positions the iframe content correctly, overlay other panels on top */}
              {website && website.finishedGeneratingAt && activePage && (
                <div
                  className={clsx({
                    'flex h-full w-full flex-col': true,
                    invisible: panelName !== 'website',
                  })}
                >
                  <PageToolbar
                    website={website}
                    colorPalette={colorPalette}
                    activePage={activePage}
                    element={clickedElementEvent?.element}
                    onViewportWidthChange={changeViewportWidth}
                    onNewPageClicked={onNewPageClicked}
                    onDeletePageClicked={deletePage}
                    changeActivePage={changeActivePage}
                    onUndoClick={onUndoClick}
                    onRedoClick={onRedoClick}
                    onSaveClick={onSaveClick}
                    onColorChangeClick={onColorChangeClick}
                    isWebsiteSaving={isSaving}
                    didSaveError={didSaveError}
                    undoDisabled={historyIndex === 0}
                    redoDisabled={
                      history.length === 0 ||
                      (history.length > 0 &&
                        historyIndex === history.length - 1)
                    }
                    onFontChange={handleFontChange}
                    onFontWeightChange={handleFontWeightChange}
                    onFontStyleChange={handleFontStyleChange}
                    onLinkChange={handleLinkChange}
                  />
                  <div className="relative flex-1 overflow-hidden">
                    <div
                      className={clsx({
                        'absolute inset-0': true,
                        invisible: isGalleryOpen,
                      })}
                    >
                      <WebsitePanel
                        ref={websitePanelRef}
                        website={website}
                        activePage={activePage}
                        websiteWidth={websiteWidth}
                        navigateToPanel={navigateToPanel}
                        navigateToWebsitePage={navigateToWebsitePage}
                        onFrameElementClick={onFrameElementClick}
                        onAddNewCodeSectionAbove={onAddNewCodeSectionAbove}
                        onAddNewCodeSectionBelow={onAddNewCodeSectionBelow}
                        onMoveClickedCodeSectionUp={onMoveClickedCodeSectionUp}
                        onMoveClickedCodeSectionDown={
                          onMoveClickedCodeSectionDown
                        }
                        onDeleteClickedCodeSection={onDeleteClickedCodeSection}
                        onPageChange={onPageChange}
                        onPageFrameLinkClick={onPageFrameLinkClick}
                        onPageFrameGeneratePageClick={
                          onPageFrameGeneratePageClick
                        }
                        colorPalette={colorPalette}
                      />
                    </div>
                    {isGalleryOpen && (
                      <div className="absolute inset-0 z-10 flex-1 overflow-auto">
                        <GalleryPanel
                          onClose={() => {
                            setIsGalleryOpen(false)
                          }}
                          onMediaSelected={handleMediaSelected}
                          initialGalleryType={galleryType}
                          website={website}
                          activePage={activePage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {panelName !== 'website' && (
                <div className="absolute inset-0 z-10 flex-1 overflow-auto">
                  {panelName === 'settings' && website && (
                    <>
                      <div className="h-full flex-1 overflow-auto">
                        <SettingsPanel
                          ref={settingsPanelRef}
                          website={website}
                          initialTab={settingsPanelInitialTab}
                          onClose={() => navigateToPanel('website')}
                        />
                      </div>
                    </>
                  )}
                  {panelName === 'templatePicker' && website && (
                    <>
                      <div className="flex-1 overflow-auto">
                        <TemplatePickerPanel
                          onTemplatePicked={onTemplatePicked}
                        />
                      </div>
                    </>
                  )}
                  {panelName === 'websiteLoading' && website && (
                    <>
                      <WebsiteLoadingProgressBar
                        website={website}
                        activePage={activePage}
                        onShowWebsiteClicked={onShowWebsiteClicked}
                      />
                      <div className="flex-1 overflow-auto">
                        <WebsiteLoadingPanel
                          onPickFavoriteImagesSelected={
                            onPickFavoriteImagesSelected
                          }
                        />
                      </div>
                    </>
                  )}
                  {panelName === 'favoriteImagePicker' && website && (
                    <>
                      <WebsiteLoadingProgressBar
                        website={website}
                        activePage={activePage}
                        onShowWebsiteClicked={onShowWebsiteClicked}
                      />
                      <div className="flex-1 overflow-auto">
                        <FavoriteImagePickerPanel
                          ref={favoriteImagePickerPanelRef}
                          websiteId={website.id}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default WebsiteChatPage
